// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-attributions-js": () => import("./../../../src/pages/attributions.js" /* webpackChunkName: "component---src-pages-attributions-js" */),
  "component---src-pages-cookie-js": () => import("./../../../src/pages/cookie.js" /* webpackChunkName: "component---src-pages-cookie-js" */),
  "component---src-pages-ebooks-js": () => import("./../../../src/pages/ebooks.js" /* webpackChunkName: "component---src-pages-ebooks-js" */),
  "component---src-pages-ebooks-kumadex-introductory-vocab-js": () => import("./../../../src/pages/ebooks/kumadex-introductory-vocab.js" /* webpackChunkName: "component---src-pages-ebooks-kumadex-introductory-vocab-js" */),
  "component---src-pages-ebooks-kumadex-jlpt-n-2-25-words-js": () => import("./../../../src/pages/ebooks/kumadex-jlpt-n2-25-words.js" /* webpackChunkName: "component---src-pages-ebooks-kumadex-jlpt-n-2-25-words-js" */),
  "component---src-pages-ebooks-kumadex-jlpt-n-3-25-words-js": () => import("./../../../src/pages/ebooks/kumadex-jlpt-n3-25-words.js" /* webpackChunkName: "component---src-pages-ebooks-kumadex-jlpt-n-3-25-words-js" */),
  "component---src-pages-ebooks-kumadex-jlpt-n-4-25-words-js": () => import("./../../../src/pages/ebooks/kumadex-jlpt-n4-25-words.js" /* webpackChunkName: "component---src-pages-ebooks-kumadex-jlpt-n-4-25-words-js" */),
  "component---src-pages-ebooks-kumadex-jlpt-n-5-50-words-js": () => import("./../../../src/pages/ebooks/kumadex-jlpt-n5-50-words.js" /* webpackChunkName: "component---src-pages-ebooks-kumadex-jlpt-n-5-50-words-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

